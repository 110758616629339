<template>
  <div class="account">
    <div class="cover"></div>
    <div class="container">
      <div class="row">
        <div class="d-none d-lg-block col-lg-6 align-self-center">
          <h1 class="info"></h1>
        </div>
        <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-2">
          <div class="card">
            <h4 class="card-title">Passwort geändert!</h4>
            <p>Dein Passwort wurde erfolgreich geändert. Du kannst dich jetzt mit deinem neuen Passwort anmelden.</p>
            <form action="">
              <div class="form-group">
                <span class="material-icons light left">person</span>
                <input type="password" class="form-control icon-left lg" placeholder="E-Mail">
              </div>
              <div class="form-group">
                <span class="material-icons light left">lock</span>
                <input type="password" class="form-control icon-left lg" placeholder="Passwort">
              </div>

              <button class="btn btn-block btn-hover">Anmelden</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer Margin -->
    <div class="footer-margin"></div>
  </div>
</template>

<script>
  export default {
    name: 'changePassword'
  }
</script>
